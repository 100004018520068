@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');

@font-face {
    font-family: 'Coolvetica';
    src: url('CoolveticaBk-Regular.eot');
    src: url('CoolveticaBk-Regular.eot#iefix') format('embedded-opentype'),
    url('CoolveticaBk-Regular.woff2') format('woff2'),
    url('CoolveticaBk-Regular.woff') format('woff'),
    url('CoolveticaBk-Regular.ttf') format('truetype'),
    url('CoolveticaBk-Regular.svg#CoolveticaBk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.eot');
    src: url('Montserrat-Regular.eot#iefix') format('embedded-opentype'),
    url('Montserrat-Regular.woff2') format('woff2'),
    url('Montserrat-Regular.woff') format('woff'),
    url('Montserrat-Regular.ttf') format('truetype'),
    url('Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.eot');
    src: url('Montserrat-Medium.eot#iefix') format('embedded-opentype'),
    url('Montserrat-Medium.woff2') format('woff2'),
    url('Montserrat-Medium.woff') format('woff'),
    url('Montserrat-Medium.ttf') format('truetype'),
    url('Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.eot');
    src: url('Montserrat-SemiBold.eot#iefix') format('embedded-opentype'),
    url('Montserrat-SemiBold.woff2') format('woff2'),
    url('Montserrat-SemiBold.woff') format('woff'),
    url('Montserrat-SemiBold.ttf') format('truetype'),
    url('Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Bold.eot');
    src: url('Montserrat-Bold.eot#iefix') format('embedded-opentype'),
    url('Montserrat-Bold.woff2') format('woff2'),
    url('Montserrat-Bold.woff') format('woff'),
    url('Montserrat-Bold.ttf') format('truetype'),
    url('Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

